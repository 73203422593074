/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { map, get, size, forEach } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { StandaloneFatIconCheckboxField } from './form/CheckboxField'
import { molliePaymentList } from '../actions/mollie_payment'
import { BlueOutlineButton } from './layout/BlueOutlineButton'
import { NewSubscriptionCostSupplement } from './NewSubscriptionCostSupplement'
import { TermsAndConditionsField } from './TermsAndConditionsField'
import { FormikRadioGroupField } from './form/RadioGroupField'
import { CheckboxButton } from './layout/CheckboxButton'
import { getBestLanguageCode } from '../actions/language'
import NestedWrappingBusyMask from './NestedWrappingBusyMask'
import CurrencyValue from './CurrencyValue'
import { BlueButton } from './layout/BlueButton'
import { GrayButton } from './layout/GrayButton'
import Timestamp from './Timestamp'
import { default_theme as theme } from '../emotion/theme'
import { Separator } from './layout/Separator'
import { showSuccess, showError } from '../actions/Error'
import { topupList } from '../actions/topup'
import CardInfoText from './layout/CardInfoText'
import { CreditTopupCard } from './CreditTopupCard'
import CardBlackInfoText from './layout/CardBlackInfoText'
import {
  Row,
  Col,
  Container,
  Popover,
  OverlayTrigger,
  Button,
} from 'react-bootstrap'
import { SubscriptionPhoneNumberSelector } from './SubscriptionPhoneNumberSelector'
import { NumberPortingForm } from './NumberPortingForm'
import { phoneNumberIsValid } from './form/PhoneNumberInputField'
import { ProductCard } from './ProductCard'
import { productList } from '../actions/product'
import { productCostCalculator } from '../actions/product_cost_calculator'
import { subscriptionList } from '../actions/customer_subscription'
import { handleSubmitResult } from '../actions/form'
import { globalSettingsList } from '../actions/settings'
import { customerList } from '../actions/customer'
import * as Yup from 'yup'

const NUMBER_SOURCE_OPTIONS = [
  { value: 'new', label: 'Get a new number' },
  { value: 'port', label: 'Bring your existing number' },
]
const PRODUCT_TYPE = [
  { value: 'voice', label: 'Voice subscriptions' },
  { value: 'fax', label: 'Fax subscriptions' },
]

const BILLING_FREQUENCY_OPTIONS = [
    { value: 'monthly', label: 'Monthly' },
    { value: 'annually', label: 'Yearly' },
]

export const validationSchema = Yup.object().shape({
  product: Yup.string().required('Required'),
})

export const NewSubscriptionCardForm = ({
  formik_props,
  initial_product_short_id,
  onNewPaymentCostCalculationId,
  onNewSelectedTopupId,
  ...props
}) => {
  const dispatch = useDispatch()
  const [processingSubscription, setProcessingSubscription] = useState(false)
  const is_loading = useSelector(
    () => !productList.isReady() || productList.isLoading()
  )
  const is_saving = useSelector(
    () =>
      subscriptionList.getIsSavingObject() ||
      molliePaymentList.getIsSavingObject() ||
      processingSubscription
  )
  const products = useSelector(() => productList.getVisibleObjects())
  const fax_products = products.some(
    (product) => product.can_send_faxes || product.can_receive_faxes
  )
  const voice_products = products.some((product) => product.can_receive_voice)
  const voice_and_fax_products = fax_products && voice_products

  const helpdesk_email = useSelector(() =>
    globalSettingsList.getSetting('helpdesk_email')
  )
  const helpdesk_phone = useSelector(() =>
    globalSettingsList.getSetting('helpdesk_phone')
  )
  const external_info_urls = useSelector(() =>
    globalSettingsList.getSetting('external_info_urls')
  )
  const credit_details_link = get(external_info_urls, [
    'xoip_credit',
    getBestLanguageCode(),
  ])

    const [selectedProductInfo, setSelectedProductInfo] = useState(null)

  const { values } = formik_props
  const number_source = get(formik_props, ['values', 'number_source'])
  const number_is_allocated =
    number_source === 'new' &&
    Boolean(get(formik_props, ['values', 'phone_number']))
  const number_is_allocated_by_admin =
    number_source === 'new' &&
    get(formik_props, ['values', 'allocation_method']) === 'by_admin'
  const porting_number_is_set =
    number_source === 'port' &&
    Boolean(get(formik_props, ['values', 'porting_number'])) &&
    phoneNumberIsValid(get(formik_props, ['values', 'porting_number']))
  const [selectedTopup, setSelectedTopup] = useState()

  const customer = useSelector(() => customerList.getCustomer())
  const hasCredit = get(customer, 'credits_excluding_vat_euros', null) > 0
  const topups = useSelector(() => topupList.getVisibleObjects())

  const can_render_number_info = get(
    selectedProductInfo,
    'requires_a_phone_number'
  )
  const phoneNumberSelectionOk =
    (selectedProductInfo && !selectedProductInfo.requires_a_phone_number) ||
    number_is_allocated_by_admin ||
    number_is_allocated ||
    porting_number_is_set
  const shouldShowTopupSelector =
    phoneNumberSelectionOk &&
    !hasCredit &&
    selectedProductInfo &&
    selectedProductInfo.requires_topup_when_creating_subscription

  const can_calculate_payment_info =
    phoneNumberSelectionOk && (!shouldShowTopupSelector || selectedTopup)
  const number_assignment_will_be_delayed =
    number_is_allocated_by_admin || number_source === 'port'
  const [agreedTermsAndConditions, setAgreedTermsAndConditions] =
    useState(false)
  const [paymentCostCalculationId, setPaymentCostCalculationId] = useState(null)
  const paymentCostCalculation =
    paymentCostCalculationId &&
    productCostCalculator.getObject(paymentCostCalculationId)
  const isCalculatingPaymentCost = productCostCalculator.getIsSavingObject()

  useEffect(() => {
    dispatch(productList.fetchListIfNeeded())
    dispatch(customerList.ensureCustomerLoaded())
    dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
    dispatch(topupList.fetchListIfNeeded())
    if (!formik_props.values.product_type) {
      formik_props.setFieldValue('product_type', 'voice')
    }
  }, [])

  useEffect(() => {
    forEach(products, (product) => {
      if (product.short_id === initial_product_short_id) {
        onChangeProduct(product)
      }
    })
  }, [initial_product_short_id])

  useEffect(() => {
    if (can_calculate_payment_info) {
      const values = Object.assign({}, formik_props.values, {
        topup: get(selectedTopup, 'id'),
      })
      dispatch(productCostCalculator.calculatePaymentCost(values))
      setPaymentCostCalculationId(values.calculation_id)
      onNewPaymentCostCalculationId(values.calculation_id)
    } else {
      setPaymentCostCalculationId(null)
      onNewPaymentCostCalculationId(null)
    }
  }, [can_calculate_payment_info, JSON.stringify(formik_props.values)])

  const onChangeProduct = (product) => {
    formik_props.setFieldValue('product', product.id)

    // This is hacky, it implies knowledge of the Selector object, needs refactoring.
    formik_props.setFieldValue('country', undefined)
    formik_props.setFieldValue('number_type', 'xoip')
    formik_props.setFieldValue('city', undefined)
    formik_props.setFieldValue('allocation_method', undefined)
    formik_props.setFieldValue('phone_number', undefined)
    formik_props.setFieldValue('number_source', 'new')

    setSelectedProductInfo(product)
  }

  const onPayWithCredits = () => {
      formik_props.setFieldValue('payment_method', 'credits')
      formik_props.submitForm()
  }

  const onTopupSelected = (topup) => {
    setSelectedTopup(topup)
    onNewSelectedTopupId(get(topup, 'id'))
  }

  const onAgreedTermsAndConditions = (checked) => {
    setAgreedTermsAndConditions(checked)
    formik_props.setFieldValue('agreed_to_terms_and_conditions', checked)
  }

  const renderProductSelectorCard = (product, index) => {
    const is_selected = get(selectedProductInfo, 'id') === product.id
    return (
      <ProductCard
        product={product}
        key={index}
        is_selected={is_selected}
        billing_frequency={formik_props.values.billing_frequency}
        onSelect={() => onChangeProduct(product)}
      />
    )
  }

  const renderNumberSelector = () => {
    return (
      <>
        {selectedProductInfo.supports_number_porting && (
          <>
            <FormikRadioGroupField
              name="number_source"
              formik_props={formik_props}
              options={NUMBER_SOURCE_OPTIONS}
            />
            <Separator variant="h25" />
          </>
        )}
        {(get(formik_props, ['values', 'number_source']) === 'new' ||
          !selectedProductInfo.supports_number_porting) && (
          <SubscriptionPhoneNumberSelector
            field_name="phone_number"
            product={selectedProductInfo}
            formik_props={formik_props}
          />
        )}
        {get(formik_props, ['values', 'number_source']) === 'port' && (
          <NumberPortingForm
            product={selectedProductInfo}
            formik_props={formik_props}
            field_name="porting_number"
            paymentCostCalculation={paymentCostCalculation}
          />
        )}
      </>
    )
  }

  const renderTopupSelector = () => {
    return (
      <>
        <Separator variant="h10" />
        <h2>
          <Trans>XOIP credit</Trans>
        </h2>
        <CardInfoText>
          <Trans i18nKey="new_subscription_card_form_xoip_credit_blurb">
            You do not (yet) have XOIP credits. A free subscription requires
            credits to be of any use. Please make a credit purchase to activate
            the number.
          </Trans>
        </CardInfoText>
        <Separator variant="h10" />
        <div css={topup_list_style}>
          {map(topups, (topup, index) => (
            <CreditTopupCard
              key={index}
              topup={topup}
              purchaseLabel="Select"
              selectedLabel="Selected"
              onSelected={onTopupSelected}
              isSelected={get(selectedTopup, 'id') === topup.id}
              style={topup_card_style}
              selectedStyle={topup_card_selected_style}
              renderButton={(is_selected) => (
                <CheckboxButton isSelected={is_selected} />
              )}
            />
          ))}
        </div>
        <CardInfoText>
          <Trans i18nKey="new_subscription_card_form_xoip_credit_validity">
            Your credits will remain valid as long as you remain an active
            subscriber.
          </Trans>
          &nbsp;
          <a target="_blank" href={credit_details_link}>
            <Trans i18nKey="new_subscription_card_form_xoip_credit_details_link">
              Check here for details.
            </Trans>
          </a>
        </CardInfoText>
      </>
    )
  }

  const renderPaymentInfo = ({ paymentCostCalculation }) => {
    return (
      <div css={payment_info_style}>
        <Separator variant="h35" />

        {paymentCostCalculation.requires_payment_to_create && <h2>Payment</h2>}

        <CardBlackInfoText>
          {selectedProductInfo.requires_a_phone_number &&
            !paymentCostCalculation.number_assignment_will_be_delayed && (
              <>
                {paymentCostCalculation.requires_payment_to_create && (
                  <Trans
                    i18nKey="new_customer_subscription__after_payment_your_subscription_will_be_activated"
                    defaults="After payment, you will be sent an email when your phone number is ready, at which point your subscription will be activated."
                  />
                )}
              </>
            )}
        </CardBlackInfoText>

        <Separator variant="h10" />

        {paymentCostCalculation.requires_payment_to_create && (
          <Row>
            <Col md={12} css={payment_line_items_style}>
              {map(
                paymentCostCalculation.payment_line_items,
                (payment_line_item) => (
                  <>
                    <Row
                      css={
                        payment_line_item.type === 'total'
                          ? your_payment_row_style
                          : null
                      }
                    >
                      <Col>
                        {payment_line_item.type === 'vat' && (
                          <>
                            {paymentCostCalculation.vat_percentage}%&nbsp;
                            <Trans>VAT</Trans>:
                          </>
                        )}
                        {payment_line_item.type !== 'vat' && (
                          <Trans>{payment_line_item.label}</Trans>
                        )}
                      </Col>
                      <Col>
                        <CurrencyValue
                          align_right={true}
                          value={payment_line_item.cost_euros}
                        />
                      </Col>
                    </Row>
                  </>
                )
              )}
            </Col>
          </Row>
        )}

        <Separator variant="h15" />

        <TermsAndConditionsField
          renderCheckbox={() => (
            <StandaloneFatIconCheckboxField
              checked={agreedTermsAndConditions}
              onChange={onAgreedTermsAndConditions}
            />
          )}
        />
        <Separator variant="h30" />

        {agreedTermsAndConditions && !isCalculatingPaymentCost && 
         <>
           {paymentCostCalculation.requires_payment_to_create &&
            <div css={pay_button_bar_style}>
              <BlueButton type="submit">
                <Trans>Pay now</Trans>
              </BlueButton>
              {paymentCostCalculation.can_pay_with_credits &&
               <>
                 <Separator variant="w10" />
                 <div css={pay_with_credits_or_style}>
                   <Trans>or</Trans>
                 </div>
                 <Separator variant="w10" />
                 <BlueOutlineButton onClick={onPayWithCredits} auto_disable={false} >
                   <Trans>Pay with XOIP Credits</Trans>
                 </BlueOutlineButton>
               </>
              }
            </div>
           }
           {!paymentCostCalculation.requires_payment_to_create &&
            <>
              <BlueButton type="submit">
                <Trans>Start your subscription</Trans>
              </BlueButton>
            </>
           }
         </>
        }
        {!agreedTermsAndConditions && !isCalculatingPaymentCost &&
         <>
           {paymentCostCalculation.requires_payment_to_create &&
            <div css={pay_button_bar_style}>
              <GrayButton disabled={true} onClick={{}}>
                <Trans>Pay now</Trans>
              </GrayButton>
              {paymentCostCalculation.can_pay_with_credits &&
               <>
                 <Separator variant="w10" />
                 <div css={pay_with_credits_or_style}>
                   <Trans>or</Trans>
                 </div>
                 <Separator variant="w10" />
                 <GrayButton disabled={true} auto_disable={false} onClick={{}}>
                   <Trans>Pay with XOIP Credits</Trans>
                 </GrayButton>
               </>
              }
            </div>
           }
           {!paymentCostCalculation.requires_payment_to_create &&
            <>
              <GrayButton disabled={true} onClick={{}}>
                <Trans>Start your subscription</Trans>
              </GrayButton>
            </>
           }
         </>
        }
      </div>
    )
  }

  return (
    <>
      <h2>
        <Trans>Select your new subscription</Trans>
      </h2>
      <Separator variant="h10" />

      <FormikRadioGroupField
          name="billing_frequency"
          formik_props={formik_props}
          options={BILLING_FREQUENCY_OPTIONS}
      />

      <Separator variant="h20" />
      
      {voice_and_fax_products && (
        <>
          <FormikRadioGroupField
            name="product_type"
            formik_props={formik_props}
            options={PRODUCT_TYPE}
            onChange={() => setSelectedProductInfo(null)}
          />
          <Separator variant="h30" />
        </>
      )}

      {voice_and_fax_products &&
        get(formik_props, ['values', 'product_type']) === 'voice' && (
          <div css={product_list_style}>
            {map(
              products.filter((product) => product.can_receive_voice),
              (product, index) => renderProductSelectorCard(product, index)
            )}
          </div>
        )}
      {!voice_and_fax_products && (
        <div css={product_list_style}>
          {map(
            products.filter((product) => product.can_receive_voice),
            (product, index) => renderProductSelectorCard(product, index)
          )}
        </div>
      )}

      {(!voice_and_fax_products ||
        get(formik_props, ['values', 'product_type']) === 'fax') && (
        <div css={fax_product_list}>
          {products.some(
            (product) =>
              !product.can_receive_faxes && !product.can_receive_voice
          ) && (
            <div>
              <div css={fax_grouping_header}>
                <hr />
                <div>
                  <Trans>Fax sending only</Trans>
                </div>
                <hr />
              </div>
              <div css={product_list_style}>
                {map(
                  products.filter(
                    (product) =>
                      !product.can_receive_faxes && !product.can_receive_voice
                  ),
                  (product, index) => renderProductSelectorCard(product, index)
                )}
              </div>
            </div>
          )}

          {products.some((product) => product.can_receive_faxes) && (
            <div>
              <div css={fax_grouping_header}>
                <hr />
                <div>
                  <Trans>Fax sending and receiving</Trans>
                </div>
                <hr />
              </div>
              <div css={product_list_style}>
                {map(
                  products.filter((product) => product.can_receive_faxes),
                  (product, index) => renderProductSelectorCard(product, index)
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <Separator variant="h20" />

      <Row>
        <Col md={6}>
          <Trans
            i18nKey="new_subscription__if_you_need_multiple"
            values={{
              helpdesk_email: helpdesk_email,
              helpdesk_phone: helpdesk_phone,
            }}
            components={[
              <a css={helpdesk_menu__link} href={`mailto:${helpdesk_email}`}>
                {helpdesk_email}
              </a>,
              <a css={helpdesk_menu__link} href={`tel:${helpdesk_phone}`}>
                {helpdesk_phone}
              </a>,
            ]}
            defaults="If you need multiple numbers for your organisation, please contact the XOIP helpdesk at <0>{{helpdesk_email}}</0> or call <1>{{helpdesk_phone}}</1>."
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Separator variant="h25" />
          {can_render_number_info && (
            <>
              <h2>
                <Trans>Your number</Trans>
              </h2>
              {renderNumberSelector()}
            </>
          )}
          {shouldShowTopupSelector && <>{renderTopupSelector()}</>}
          {can_calculate_payment_info && (
            <NestedWrappingBusyMask is_loading={isCalculatingPaymentCost}>
              {paymentCostCalculation &&
                renderPaymentInfo({ paymentCostCalculation })}
            </NestedWrappingBusyMask>
          )}
        </Col>
      </Row>
    </>
  )
}

const product_list_style = css`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`
const fax_product_list = css`
  display: flex;
  flex-wrap: wrap;
  gap: 50px 20px;
`
const fax_grouping_header = css`
  display: flex;
  gap: 16px;
  padding: 0 16px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;

  & > hr {
    flex-grow: 1;
    @media (max-width: 768px) {
      display: none;
    }
  }
`
const payment_info_style = css``

const your_payment_row_style = css`
  font-weight: bold;
`

const helpdesk_menu__link = css`
  color: ${theme.colors.primary} !important;
  white-space: nowrap;
`

const topup_list_style = css`
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
`

const topup_card_style = css`
  border: 2px solid ${theme.colors.nearly_white};
  border-radius: 20px;
  cursor: pointer;
`

const topup_card_selected_style = css`
  border: 2px solid ${theme.colors.primary_orange};
`

const pay_with_credits_or_style = css`
padding-top: 5px;
font-weight: bold;
`

const pay_button_bar_style = css`
display: flex;
`

const payment_line_items_style = css`
width: 300px;
`
