/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Link } from 'react-router-dom'
import CurrencyValue from './CurrencyValue'
import Timestamp from './Timestamp'
import Card from './layout/Card'
import CommonTable from './CommonTable'
import { InlineIcon } from './layout/InlineIcon'
import { CardHeader } from './layout/CardHeader'
import TableHeader from './layout/TableHeader'
import TableFilter from './layout/TableFilter'
import { Separator } from './layout/Separator'
import { DatePicker } from './form/DatetimePicker'
import { Trans, Translation } from 'react-i18next'

class InvoiceTable extends Component {

    componentDidMount() {
        const { dispatch, itemList } = this.props
        dispatch(itemList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, itemList } = this.props
        dispatch(itemList.fetchListIfNeeded())
    }
    
    onViewInvoice = (evt, item) => {
        const { history, itemList } = this.props
        itemList.download(item.id)
    }

    getCellValue = (header_key, item, index) => {
        const that = this
        switch( header_key ) {
            case 'amount_excluding_vat_euros':
                return (
                    <CurrencyValue value={item.amount_excluding_vat_euros} />
                )
            case 'invoice_date':
                return (
                    <Timestamp value={item.invoice_date} format="date" />
                )
            case 'action':
                return (
                    <InlineIcon icon_name="download" onClick={(evt) => that.onViewInvoice(evt, item)}/>
                )
            default:
                return undefined
        }
    }

    onFilterChanged = (filter_values) => {
        const { dispatch, itemList } = this.props
        dispatch(itemList.updateListFilter(filter_values))
    }

    onDateFromChanged = (value) => {
        const { dispatch, itemList } = this.props
        dispatch(itemList.updateListFilter({'invoice_date_from': value.format()}))
    }

    onDateToChanged = (value) => {
        const { dispatch, itemList } = this.props
        dispatch(itemList.updateListFilter({'invoice_date_to': value.format()}))
    }

    renderFilter() {
        const { customer_id, customer, additional_filters } = this.props
        let title = null
        if ( customer_id ) {
            title = <span><Trans>Invoices for</Trans> {customer && customer.user.display_name}</span>
        } else {
            title = <Trans>Invoices</Trans>
        }

        const filter = (<div css={header_right_child}>
                          { false && <div>
                                       <Separator variant="w30" />
                                       Date from
                                       <Separator variant="w5" />
                                       <DatePicker onChange={this.onDateFromChanged} />
                                       
                                       <Separator variant="w15" />
                                       Date to 
                                       <Separator variant="w5" />
                                       <DatePicker onChange={this.onDateToChanged} />
                                       <Separator variant="w15" />
                                     </div> }
                          {additional_filters}
                          <Translation>{ (t) => (
                              <TableFilter
                                label=""
                                placeholder={t("Invoice no., description")}
                                updateOnChange={ this.onFilterChanged }
                                form="ADMIN_FAX_LOG_TABLE_FILTER_FORM"
                              />
                          )}</Translation>
                        </div>
                       )
        
        return (
            <CardHeader title={title} right_child={filter} />
        )
    }

    render() {
        const { is_loading, headers, invoices, empty_message, itemList } = this.props
        return (
            <div>
              { this.renderFilter() }
              <Separator variant="h15" />
              <CommonTable
                  is_loading={ is_loading }
                  empty_message={empty_message}
                  headers={ headers }
                  items={ invoices }
                  item_list={itemList}
                  renderCell={ this.renderCell }
                  getCellValue={ this.getCellValue }
              />
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { itemList, empty_message, additional_filters } = props
    const invoices = itemList.getVisibleObjects()
    return {
        invoices,
        is_loading: itemList.isLoading(),
        headers: {
            amount_excluding_vat_euros: { name: <Trans>Amnt (ex VAT)</Trans> },
            invoice_date: { name: <Trans>Date</Trans> },
            invoice_number: { name: <Trans>Invoice no.</Trans> },
            description: { name: <Trans>Description</Trans> },
            action: { name: <Trans>Download</Trans>}
        },
        additional_filters,
        empty_message: empty_message || <Trans>There are no invoices</Trans>
    }
}

export default connect(mapStateToProps)(InvoiceTable)

const invoice_modal_currency_value_row_style = css`
display: flex;
`

const header_right_child = css`
display: flex;
align-items: center;
`
