import { ItemList } from '../../orm'

class AdminMolliePaymentList extends ItemList {
    getEntityKey() {
        return "admin/mollie_payment"
    }
}

export const adminIncomingMolliePaymentList = new AdminMolliePaymentList("admin_mollie_payment__default")
export const adminOutgoingMolliePaymentList = new AdminMolliePaymentList("admin_outgoing_mollie_payment__default")
