/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { compact, size, map, get, forEach } from 'lodash'
import { Modal } from 'react-bootstrap'
import { ButtonBar } from './layout/ButtonBar'
import LoadingOverlay from './layout/loading_overlay/LoadingOverlay'
import { PaySubscriptionsWithCreditsResult } from './PaySubscriptionsWithCreditsResult'
import { PaySubscriptionsWithCreditsConfirmation } from './PaySubscriptionsWithCreditsConfirmation'
import { PhoneNumber } from './PhoneNumber'
import { Timestamp } from './Timestamp'
import { Trans, Translation } from 'react-i18next'
import { StandaloneFatIconCheckboxField } from './form/CheckboxField'
import { TermsAndConditionsField } from './TermsAndConditionsField'
import { InlineIcon } from './layout/InlineIcon'
import { CardHeader } from './layout/CardHeader'
import { customerList } from '../actions/customer'
import { faxCostStructureVariantList } from '../actions/fax_cost_structure_variant'
import CardParagraphHeading from './layout/CardParagraphHeading'
import CardParagraphSubHeading from './layout/CardParagraphSubHeading'
import BusyMask from './BusyMask'
import CardInfoText from './layout/CardInfoText'
import { Button } from 'react-bootstrap'
import { BlueButton } from './layout/BlueButton'
import { BlueOutlineButton } from './layout/BlueOutlineButton'
import { GrayButton } from './layout/GrayButton'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { molliePaymentList } from '../actions/mollie_payment'
import { customerSubscriptionPayableList } from '../actions/customer_subscription'
import { customerSubscriptionManualReservationForPaymentList } from '../actions/customer_subscription_manual_reservation'
import { Separator } from './layout/Separator'
import CurrencyValue from './CurrencyValue'
import FieldInfoText from './layout/FieldInfoText'
import MainLayout from './MainLayout'
import WrappingBusyMask from './WrappingBusyMask'
import { default_theme as theme } from '../emotion/theme'
import { handleSubmitResult } from '../actions/form'

class PaySubscriptions extends Component {

    constructor(props) {
        super(props)
        this.state = {navigating_to_mollie: false,
                      agreedTermsAndConditions: false,
                      showMakePaymentWithCreditsConfirmationModalForSubscription: null,
                      payWithCreditsResult: null}
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(customerSubscriptionPayableList.updateListFilter({payment_is_due: true}))
        dispatch(customerSubscriptionPayableList.fetchListIfNeeded())
        dispatch(customerList.ensureCustomerLoaded())
        this.fetchMatchingReservations()
        this.fetchFaxCostStructureVariants()
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(customerSubscriptionPayableList.fetchListIfNeeded())
        this.fetchMatchingReservations()
        this.fetchFaxCostStructureVariants()
    }

    fetchMatchingReservations = () => {
        const { dispatch, reservation_ids } = this.props
        dispatch(customerSubscriptionManualReservationForPaymentList.ensureObjectsLoaded(reservation_ids))
    }

    fetchFaxCostStructureVariants = () => {
        const { dispatch, faxCostStructureVariantIds } = this.props
        dispatch(faxCostStructureVariantList.ensureObjectsLoaded(faxCostStructureVariantIds))
    }

    onAgreedTermsAndConditions = (checked) => {
        this.setState({agreedTermsAndConditions: checked})
    }
    
    onMakePayment = (subscription) => {
        const { dispatch } = this.props
        const { agreedTermsAndConditions } = this.state
        const that = this

        const on_ok = function(mollie_record) {
            if ( mollie_record.checkout_url ) {
                that.setState({navigating_to_mollie: true})
                molliePaymentList.rememberPendingMolliePaymentId(mollie_record.id)
                window.location = mollie_record.checkout_url
            }
        }

        molliePaymentList.rememberAsReturnUrl()
        dispatch(molliePaymentList.startPaySubscription({subscription, agreedTermsAndConditions})).then((res) => handleSubmitResult({res, on_ok}))
    }

    onShowMakePaymentWithCreditsConfirmationModal = (subscription) => {
        this.setState({showMakePaymentWithCreditsConfirmationModalForSubscription: subscription})
    }

    onCloseMakePaymentWithCreditsConfirmationModal = () => {
        this.setState({showMakePaymentWithCreditsConfirmationModalForSubscription: null})
    }

    onMakePaymentWithCredits = (subscription) => {
        const { dispatch } = this.props
        const that = this

        const on_ok = function(subscription) {
            dispatch(customerList.invalidateCustomer())
            dispatch(customerList.ensureCustomerLoaded())
            dispatch(customerSubscriptionPayableList.invalidateObject(subscription.id))
            dispatch(customerSubscriptionPayableList.invalidateList())
            dispatch(customerSubscriptionPayableList.fetchListIfNeeded())
            that.setState({payWithCreditsResult: {status: 'success'},
                           showMakePaymentWithCreditsConfirmationModalForSubscription: null})
        }
        
        dispatch(customerSubscriptionPayableList.payWithCredits({subscription_id: subscription.id})).then((res) => handleSubmitResult({res, on_ok}))
    }

    onClosePayWithCreditsResultsModal = () => {
        const { history } = this.props
        this.setState({payWithCreditsResult: null})
        history.goBack()
    }

    renderMakePaymentWithCreditsConfirmationModal = () => {
        const { customer, is_saving } = this.props
        const subscription = this.state.showMakePaymentWithCreditsConfirmationModalForSubscription
        
        return (
            <PaySubscriptionsWithCreditsConfirmation
                isSaving={is_saving}
                customer={customer}
                subscription={subscription}
                onConfirm={() => this.onMakePaymentWithCredits(subscription)}
                onCancel={this.onCloseMakePaymentWithCreditsConfirmationModal}
            />
            
        )
    }
    
    renderPayWithCreditsResult = () => {
        return <PaySubscriptionsWithCreditsResult onClose={this.onClosePayWithCreditsResultsModal} />
    }

    renderPhoneNumber(subscription) {
        return (
            <>
              { subscription.product_can_receive_faxes && 
                <div>
                  { subscription.phone_number_number &&
                    <>
                      <Trans>Receive faxes on your number</Trans>
                      :&nbsp;
                      <span css={bold_style}> 
                        <PhoneNumber phone_number={subscription.phone_number_number} />
                      </span>
                    </>
                  }
                  
                  { !subscription.phone_number_number && get(subscription, ["reservation_details", "number_source"]) === "port" &&
                    <>
                      <Trans>Receive faxes on your number</Trans>:
                      <span css={bold_style}> 
                        <PhoneNumber phone_number={subscription.reservation_details.number} />
                      </span>
                      <br/>
                      <CardInfoText>
                        <Trans>The number will be transferred to XOIP</Trans>
                      </CardInfoText>
                    </>
                  }
                  
                  { !subscription.phone_number_number && get(subscription, ["reservation_details", "number_source"]) === "new" &&
                    <>
                      <Trans>Receive faxes on your number in</Trans>&nbsp;
                      <PhoneNumber phone_number={get(subscription, ["reservation_details", "phone_number_prefix", "prefix"])} />
                      { get(subscription, ["reservation_details", "phone_number_prefix", "city_name"]) &&
                        <span>, {subscription.reservation_details.phone_number_prefix.city_name}</span>
                      }.
                      <CardInfoText>
                        <Trans>We'll notify you when the number is available</Trans>
                      </CardInfoText>
                    </>
                  }
                  <Separator variant="h10" />
                </div>
              }
            </>
        )
    }

    renderPayment(subscription) {
        const { customer } = this.props
        const { agreedTermsAndConditions } = this.state
        const alreadyAgreedToTermsAndConditions = Boolean(subscription.agreed_to_terms_and_conditions_at)
        const can_pay = alreadyAgreedToTermsAndConditions || agreedTermsAndConditions
        
        const reservation = get(subscription, "reservation_details")
        const number_source = get(reservation, "number_source")
        const that = this
        return (
            <>
              <div css={heading_style}>
                <div css={mini_card_row}>
                  <h2>Payment</h2>
                </div>
                <Separator variant="h5" />
              </div>

              <Row>
                <Col md={12}>
                  <Row>
                    <Col><Trans>Subscription</Trans>:</Col>
                    <Col><Trans><CurrencyValue align_right={true} value={subscription.cost_per_period_excluding_vat_euros} /></Trans></Col>
                  </Row>

                  { Boolean(subscription.is_waiting_for_first_activation && subscription.setup_price_excluding_vat_euros) && 
                    <Row>
                      <Col>
                        { number_source === "port" &&
                          <span><Trans>Number portability</Trans>:</span>
                        }
                        { number_source !== "port" &&
                          <span><Trans>Setup costs</Trans>:</span>
                        }
                      </Col>
                      <Col>
                        <CurrencyValue align_right={true} value={subscription.setup_price_excluding_vat_euros} />
                      </Col>
                    </Row>
                  }

                  { Boolean(subscription.can_reactivate && subscription.reactivation_price_excluding_vat_euros) &&
                    <Row>
                      <Col>
                          <span><Trans>Re-activation fee</Trans>:</span>
                      </Col>
                      <Col>
                        <CurrencyValue align_right={true} value={subscription.reactivation_price_excluding_vat_euros} />
                      </Col>
                    </Row>
                  }
                  
                  <Row>
                    <Col>{subscription.amount_owing_vat_percentage}% <Trans>VAT</Trans>:</Col>
                    <Col><Trans><CurrencyValue align_right={true} value={subscription.amount_owing_vat_euros} /></Trans></Col>
                  </Row>
                  <Row css={your_payment_row_style}>
                    <Col><Trans>Your payment</Trans>:</Col>
                    <Col><Trans><CurrencyValue align_right={true} value={subscription.amount_owing_including_vat_euros} /></Trans></Col>
                  </Row>

                </Col>
              </Row>

              <Separator variant="h15" />

              { ! alreadyAgreedToTermsAndConditions &&
                <>
                  <TermsAndConditionsField renderCheckbox={() => <StandaloneFatIconCheckboxField checked={agreedTermsAndConditions} onChange={that.onAgreedTermsAndConditions} />} />
                  <Separator variant="h30" />
                </>
              }
              
              <div css={buy_button_row_style}>
                { can_pay && 
                <BlueButton extra_css={buy_button_style} onClick={() => this.onMakePayment(subscription)}>
                  <Trans>Pay now</Trans>
                </BlueButton>
                 }
                { ! can_pay && 
                  <GrayButton disabled={true} onClick={{}}>
                    <Trans>Pay now</Trans>
                  </GrayButton>
                }

                { subscription.can_pay_with_credits &&
                  <>
                    <Separator variant="w10" />
                    <div css={pay_with_credits_or_style}>
                      <Trans>or</Trans>
                    </div>
                    <Separator variant="w10" />
                    <div>
                      { can_pay &&
                        <BlueOutlineButton onClick={() => this.onShowMakePaymentWithCreditsConfirmationModal(subscription)}>
                          <Trans>Pay with XOIP Credits</Trans>
                        </BlueOutlineButton>
                      }
                      { ! can_pay && 
                        <GrayButton disabled={true} onClick={{}}>
                          <Trans>Pay with XOIP Credits</Trans>
                        </GrayButton>
                      }
                      { false && 
                        <div css={pay_with_credits_amount_style}>
                          <Trans>Your XOIP Balance</Trans>:&nbsp;
                        <CurrencyValue value={customer?.credits_excluding_vat_euros} />
                        </div>
                      }
                    </div>
                  </>
                } 
                
              </div>
            </>
        )
    }

    tryRenderSendFaxesBlurb(subscription) {
        const { subscription_fax_cost_variant } = this.props
        return (
            <>
              { subscription.product_can_send_faxes && 
                <div>
                  
                  { subscription.product_can_receive_faxes && <Separator variant="h3" /> }

                  { ! subscription.minimum_outgoing_fax_cost_excluding_vat_euros && 
                    <Trans
                      i18nKey="paysubscriptions__send_faxes_with_no_minimum"
                      components={[<CurrencyValue use_span={true} value={get(subscription, "product_minimum_outgoing_fax_cost_per_page_excluding_vat_euros")} />]}
                      defaults="Send faxes from xoip.com or from your email, starting from <0></0> per page, without a minimum rate per fax."
                    />
                  }
                  { subscription.minimum_outgoing_fax_cost_excluding_vat_euros > 0 && 
                    <Trans
                      i18nKey="paysubscriptions__send_faxes_with_minimum"
                      components={[<CurrencyValue use_span={true} value={get(subscription, "product_minimum_outgoing_fax_cost_per_page_excluding_vat_euros")} />,
                                   <CurrencyValue use_span={true} value={get(subscription, "minimum_outgoing_fax_cost_excluding_vat_euros")} />]}
                      defaults="Send faxes from xoip.com or from your email, starting from <0></0> per page, with a minimum rate of <1></1> per fax."
                    />
                  }
                  <Separator variant="h10" />
                </div>
              }
            </>
        )
    }

    renderSubscription(subscription, index) {
        return (
            <Card key={index} css={mini_card_style}>

              <div css={heading_style}>
                <Trans>Subscription</Trans>: {get(subscription, ["product_name"])}
              </div>
              <Separator variant="h10" />

              { this.renderPhoneNumber(subscription) }
              
              { subscription.product_can_send_faxes && this.tryRenderSendFaxesBlurb(subscription) }

              { subscription.product_can_receive_voice && 
                <div>
                  <Trans
                    i18nKey="paysubscriptions__receive_phone_calls_on"
                    components={[<PhoneNumber phone_number={subscription.phone_number_number} />]}
                    defaults="Receive phone calls on <0></0>. Forward to other phone numbers and receive voicemail in your email."
                  />
                  <Separator variant="h10" />
                </div>
              }

              <Separator variant="h20" />
              <div css={line_break_style}>&nbsp;</div>
              <Separator variant="h30" />

              { subscription.is_active && subscription.expires_at && 
                <div>
                  <Trans
                    i18nKey="paysubscriptions__this_subscription_will_expire"
                    components={[<Timestamp css={bold_style} value={subscription.expires_at} format="date_longmonth" use_span={true} />]}
                    defaults="This subscription will expire on <0></0>. Pay in time to make sure your phone number remains active."
                  />
                  <Separator variant="h30" />
                </div>
              }

              { subscription.is_waiting_for_first_activation &&
                <div>
                  <div>
                    <Trans
                      i18nKey="paysubscriptions__your_subscription_will_be_activated_as_soon"
                      components={[<Timestamp css={bold_style} value={subscription.first_activation_expires_at} format="date_longmonth" use_span={true} />]}
                      defaults="Your subscription will activated as soon as you have paid. You can do this until <0></0>."
                    />
                  </div>
                  <Separator variant="h30" />
                </div>
              }

              { ! subscription.is_active && subscription.can_reactivate && 
                <div css={[expired_style]}>

                  { ! subscription.auto_recurring_billing && 
                    <Trans
                        i18nKey="paysubscriptions__your_subscription_expired_on"
                        components={[<Timestamp value={subscription.expires_at} format="date_longmonth" use_span={true} />,
                                     <Timestamp value={subscription.reactivation_available_until} format="date_longmonth" use_span={true} />]}
                        defaults="Your subscription expired on <0></0>. You can reactivate the subscription (and keep the number) until <1></1>."
                    />
                  }
                  { subscription.auto_recurring_billing &&
                    <>
                      <Trans
                          i18nKey="paysubscriptions__your_recurring_subscription_expired_on"
                          components={[<Timestamp value={subscription.expires_at} format="date_longmonth" use_span={true} />]}
                          defaults="Your subscription expired on <0></0>. We were unable to process your automatic payment. Your phone number is now temporarilly deactivated."
                      />

                      <Separator variant="h10" />
                      <Trans
                          i18nKey="paysubscriptions__your_recurring_subscription_reactivation"
                          components={[<Timestamp value={subscription.reactivation_available_until} format="date_longmonth" use_span={true} />]}
                          defaults="Please use the Pay Now button to restart automatic payments. You have until <0></0> to do so. After this the subscription will be cancelled."
                      />
                    </>
                  }

                  <Separator variant="h15" />
                </div>
              }
              
              <div css={mini_card_amount_block}>
                <div css={payment_frequency_style}>
                  { subscription.billing_frequency === 'monthly' &&
                    <Trans>BILLED MONTHLY</Trans>
                  }
                  { subscription.billing_frequency === 'annually' &&
                    <Trans>BILLED YEARLY</Trans>
                  }
                </div>
                <Separator variant="h15" />
                <div css={cost_per_period_style}>
                  <CurrencyValue value={subscription.cost_per_period_excluding_vat_euros} variant="multiheight" />
                </div>
                
                <Separator variant="h30" />

                <FieldInfoText>
                  { subscription.billing_frequency === 'monthly' &&
                    <Trans
                        i18nKey="paysubscriptions__auto_billed_each_month"
                        components={[<CurrencyValue css={bold_style} value={subscription.cost_per_period_excluding_vat_euros} use_span={true} />]}
                        defaults="Monthly payments will be deducted automatically until you cancel the subscription."
                    />
                  }
                  { subscription.billing_frequency === 'annually' &&
                    <Trans
                        i18nKey="paysubscriptions__billed_each_year"
                        components={[<CurrencyValue css={bold_style} value={subscription.cost_per_period_excluding_vat_euros} use_span={true} />]}
                        defaults="You will be notified by email four weeks before the end of the term, to make your next payment."
                    />
                  }
                </FieldInfoText>
              </div>

              <Separator variant="h30" />

              { this.renderPayment(subscription) }
              
              
            </Card>
        )
    }
    
    render() {
        const { is_ready, is_saving, subscriptions, is_loading, history } = this.props
        const { navigating_to_mollie, payWithCreditsResult, showMakePaymentWithCreditsConfirmationModalForSubscription } = this.state

        return (
            <MainLayout active_key="credit_subscription"
                        disable_action_notifications={['pay_subscriptions']}
                        breadcrumbs={[{name: 'home'},
                                      {name: 'subscription', label: 'Subscription', url: '/pay_subscription'}]}>
              <Container fluid>
                <Row>
                  <Col>
                    <CardHeader title={<Trans>Subscription payment</Trans>} />
                    <Separator variant="h10"/>

                    {size(subscriptions) > 0 && (
                        <BlueLinkButton onClick={history.goBack}>
                          <InlineIcon icon_name="chevron-left" variant="blue" />
                          <Trans>Back</Trans>
                        </BlueLinkButton>
                    )}

                    <WrappingBusyMask is_loading={is_saving || is_loading || !is_ready || navigating_to_mollie}>
                      <div css={subscription_list_style}>
                        {map(subscriptions, (subscription, index) => this.renderSubscription(subscription, index))}
                        {size(subscriptions) === 0 && (
                            <div>
                              <Trans>You have no open subscription payments</Trans>.
                              <BlueLinkButton onClick={() => history.push("/")}>
                                <Trans>Back to Home</Trans>
                              </BlueLinkButton>
                            </div>
                        )}
                      </div>
                    </WrappingBusyMask>
                  </Col>
                </Row>
              </Container>

              { payWithCreditsResult && this.renderPayWithCreditsResult() }
              { showMakePaymentWithCreditsConfirmationModalForSubscription && this.renderMakePaymentWithCreditsConfirmationModal() }
            </MainLayout>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { amount } = props
    const subscriptions = customerSubscriptionPayableList.getVisibleObjects()
    const reservation_ids = compact(map(subscriptions, (x) => x.customer_subscription_manual_reservation))
    const reservations = customerSubscriptionManualReservationForPaymentList.getObjects(reservation_ids)
    const customer = customerList.getCustomer()
    map(reservations, (reservation) => forEach(subscriptions,
                                               function(sub) {
                                                   if ( sub.customer_subscription_manual_reservation === reservation.id ) {
                                                       sub.reservation_details = reservation
                                                   }
                                               }))

    const faxCostStructureVariantIds = compact(map(subscriptions, (subscription) => subscription.fax_cost_structure_variant))
    map(subscriptions, (subscription) => subscription.fax_cost_variant = faxCostStructureVariantList.getObject(subscription.fax_cost_structure_variant))
    
    return {
        amount,
        subscriptions,
        customer,
        reservation_ids,
        faxCostStructureVariantIds,
        is_ready: customerSubscriptionPayableList.isReady(),
        is_loading: customerSubscriptionPayableList.isLoading() || (size(reservation_ids) > 0 && customerSubscriptionManualReservationForPaymentList.isLoading()),
        is_saving: molliePaymentList.getIsSavingObject() || customerSubscriptionPayableList.getIsSavingObject(),
        alreadyAgreedToTermsAndConditions: customerList.hasAgreedToTermsAndConditions()
    }
}

export default withRouter(connect(mapStateToProps)(PaySubscriptions))

const subscription_list_style = css`
display: flex;
flex-wrap: wrap;
margin-right: -30px;
`

const mini_card_style = css`
max-width: 426px;
background-color: #fff;
padding-top: 30px;
padding-bottom: 40px;
padding-left: 30px;
padding-right: 30px;
margin-top: 30px;
margin-right: 30px;
margin-bottom: 30px;
position: relative;
`

const line_break_style = css`
border-bottom: 1px solid ${theme.colors.light_middle_grey};
width: 100%;
height: 1px;
`

const buy_button_row_style = css`
display: flex;
justify-content: left-align;
`

const pay_with_credits_or_style = css`
padding-top: 5px;
font-weight: bold;
`

const buy_button_style = css`
max-height: 36px;
`

const pay_with_credits_amount_style = css`
display: flex;
justify-content: center;
font-size: ${theme.colors.light_grey};
font-size: 12px;
`

const mini_card_row = css`
display: flex;
`

const mini_card_amount_block = css`
background-color: ${theme.colors.nearly_white};
text-align: center;
padding-top: 34px;
padding-bottom: 34px;
padding-left: 20px;
padding-right: 20px;
color: #000;
`

const your_payment_amount_style = css`
font-size: 18px;
font-weight: bold;
`

const payment_frequency_style = css`
font-size: 18px;
`

const cost_per_period_style = css`
display: flex;
justify-content: center;

`

const setup_cost_style = css`
display: flex;
justify-content: center;
font-size: 18px;
`

const expired_style = css`
color: ${theme.colors.primary_red}
`

const bold_style = css`
font-weight: 500;
`

const heading_style = css`
font-weight: bold;
font-size: 18px;
`

const your_payment_row_style = css`
font-weight: bold;
`

const pay_with_xoip_credits_info_icon_style = css`
color: ${theme.colors.gray1};
`
