/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Trans, Translation } from 'react-i18next'
import { Row, Col, Container } from 'react-bootstrap'
import { includes, map } from 'lodash'
import { default_theme as theme } from '../emotion/theme'
import { customerList } from '../actions/customer'
import Card from './layout/Card'
import { Modal } from 'react-bootstrap'
import { InlineIcon } from './layout/InlineIcon'
import { WhiteButton } from './layout/WhiteButton'
import { molliePaymentList } from '../actions/mollie_payment'
import { Separator } from './layout/Separator'
import { BlueButton } from './layout/BlueButton'
import PaySubscriptionsNotification from './PaySubscriptionsNotification'
import { PortingNumberNotification } from './PortingNumberNotification'
import {
  subscriptionList,
  customerSubscriptionPayableList,
} from '../actions/customer_subscription'

class ActionNotifications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alert_message: null,
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(
      molliePaymentList.checkPendingMolliePaymentStatus(
        this.onMolliePaymentChecked
      )
    )
  }

  componentDidUpdate() {
    const { dispatch } = this.props
    dispatch(customerList.ensureCustomerLoaded())
  }

  onMolliePaymentChecked = (mollie_payment) => {
    const { dispatch } = this.props
    dispatch(customerList.invalidateCustomer())

    if (mollie_payment.payment_reason === 'subscription') {
      dispatch(customerSubscriptionPayableList.invalidateList())
      dispatch(
        customerSubscriptionPayableList.invalidateObjects(
          mollie_payment.subscriptions
        )
      )

      dispatch(subscriptionList.invalidateObjects(mollie_payment.subscriptions))
      dispatch(
        subscriptionList.ensureObjectsLoaded(mollie_payment.subscriptions)
      )
    }

    const data = { title: 'Mollie payment' }

    if (mollie_payment.mollie_status === 'paid') {
      data.text = (
        <div>
          <InlineIcon icon_name="status-success" size_variant="h24" />
          <br/>
          {mollie_payment.payment_reason === 'topup' && (
            <div>
              <Trans>
                Your payment was successful, and your balance has been updated
              </Trans>
              .
            </div>
          )}
          {mollie_payment.payment_reason === 'subscription' && (
            <div>
              <Trans>
                Your payment was successful, and your subscription has been paid
              </Trans>
              .
            </div>
          )}
        </div>
      )
    } else {
      data.text = (
        <div>
          <InlineIcon icon_name="status-error" size_variant="h24" />
          <br/>
          <Trans>
            We were not able to process your payment.
          </Trans>
          <Separator variant="h20" />
          <Trans>
            For any queries, please refer to your payment transaction:
          </Trans>
           {' '}{mollie_payment.short_ref}
        </div>
      )
    }
    this.setState({ alert_message: data })
  }

  onCloseAlertMessage = () => {
    this.setState({ alert_message: null })
  }

  renderAlertMessage(alert_message) {
    return (
      <Modal show={true} onHide={this.onCloseAlertMessage}>
        <Modal.Header closeButton>
          <Modal.Title>{alert_message.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>{alert_message.text}</div>
          <Separator variant="h10" />
          <div>
            <BlueButton onClick={this.onCloseAlertMessage}>Close</BlueButton>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  render() {
    const { customer, disable_action_notifications } = this.props
    const { alert_message } = this.state

    return (
      <div>
        {alert_message && this.renderAlertMessage(alert_message)}
        {!includes(disable_action_notifications, 'pay_subscriptions') &&
          customer.subscription_amount_owing_excluding_vat_euros > 0 && (
            <PaySubscriptionsNotification />
          )}
        <PortingNumberNotification />
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const { disable_action_notifications } = props
  const customer = customerList.getCustomer()
  return {
    customer,
    disable_action_notifications,
  }
}

export default withRouter(connect(mapStateToProps)(ActionNotifications))

const container_style = css`
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  padding: 20px;
  color: #856404;
  margin-left: 15px;
`

const header = css`
  font-size: 24px;
  font-weight: 500;
`
