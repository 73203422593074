/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { map, get, size, forEach } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { molliePaymentList } from '../actions/mollie_payment'
import { PaySubscriptionsWithCreditsResult } from './PaySubscriptionsWithCreditsResult'
import { PaySubscriptionsWithCreditsConfirmation } from './PaySubscriptionsWithCreditsConfirmation'
import WrappingBusyMask from './WrappingBusyMask'
import { customerSubscriptionPayableList } from '../actions/customer_subscription'
import Card from './layout/Card'
import { Row, Col } from 'react-bootstrap'
import { productList } from '../actions/product'
import { subscriptionList } from '../actions/customer_subscription'
import { handleSubmitResult } from '../actions/form'
import { productCostCalculator } from '../actions/product_cost_calculator'
import {
    NewSubscriptionCardForm,
    validationSchema,
} from './NewSubscriptionCardForm'
import { customerList } from '../actions/customer'
import * as Yup from 'yup'

export const NewSubscriptionCard = ({
    initial_product_short_id,
    product_filter,
    ...props
}) => {
    const dispatch = useDispatch()
    const [processingSubscription, setProcessingSubscription] = useState(false)
    const is_loading = !productList.isReady() || productList.isLoading()
    const is_saving =
        subscriptionList.getIsSavingObject() ||
        molliePaymentList.getIsSavingObject() ||
        processingSubscription
    const initial_values = { number_source: 'new',
                             billing_frequency: 'monthly'}
    
    const [paymentCostCalculationId, setPaymentCostCalculationId] = useState(null)
    const paymentCostCalculation =
        paymentCostCalculationId &&
        productCostCalculator.getObject(paymentCostCalculationId)
    const [selectedTopupId, setSelectedTopupId] = useState()
    const history = useHistory()

    const customer = useSelector(() => customerList.getCustomer())
    const [showMakePaymentWithCreditsConfirmationModalForSubscription, setShowMakePaymentWithCreditsConfirmationModalForSubscription] = useState(null)
    const [payWithCreditsResult, setPayWithCreditsResult] = useState(null)

    useEffect(() => {
        if (!(product_filter.is_fax_related && product_filter.can_receive_voice)) {
            dispatch(productList.updateListFilter(product_filter))
        }
        dispatch(productList.fetchListIfNeeded())
        dispatch(customerList.ensureCustomerLoaded())
    }, [])

    const handlePaySubscriptionWithMollie = (subscription) => {
        let cost_including_vat_euros = get(
            paymentCostCalculation,
            'total_cost_euros_inc_vat',
            subscription.amount_owing_including_vat_euros
        )

        if (!cost_including_vat_euros) {
            history.push(`/subscription_confirmation/${subscription.id}`)
        } else {
            const on_ok = (mollie_record) => {
                if (mollie_record.checkout_url) {
                    molliePaymentList.rememberPendingMolliePaymentId(mollie_record.id)
                    window.location = mollie_record.checkout_url
                }
            }
            molliePaymentList.rememberAsReturnUrl(
                `/subscription_confirmation/${subscription.id}`
            )
            dispatch(molliePaymentList.startPaySubscription({ subscription })).then(
                (res) => handleSubmitResult({ res, on_ok })
            )
        }
    }

    const onShowMakePaymentWithCreditsConfirmationModal = (values) => {
        setShowMakePaymentWithCreditsConfirmationModalForSubscription(values)
    }

    const onCloseMakePaymentWithCreditsConfirmationModal = () => {
        setShowMakePaymentWithCreditsConfirmationModalForSubscription(null)
        setProcessingSubscription(false)
    }
    
    const handlePaySubscriptionWithCredits = (values) => {
        const on_credits_pay_ok = function(subscription) {
            dispatch(customerSubscriptionPayableList.invalidateObject(subscription.id))
            dispatch(customerSubscriptionPayableList.invalidateList())
            dispatch(customerSubscriptionPayableList.fetchListIfNeeded())
            dispatch(customerList.invalidateCustomer())
            dispatch(customerList.ensureCustomerLoaded())
            setPayWithCreditsResult({status: 'success', subscription_id: subscription.id})
            setShowMakePaymentWithCreditsConfirmationModalForSubscription(null)
            history.push(`/subscription_confirmation/${subscription.id}`)
        }
        
        const on_subscription_save_ok = function(subscription) {
            dispatch(customerSubscriptionPayableList.payWithCredits({subscription_id: subscription.id})).then((res) => handleSubmitResult({res, on_ok: on_credits_pay_ok}))
        }

        setProcessingSubscription(true)
        return dispatch(subscriptionList.saveNewObject(values)).then((res) =>
                handleSubmitResult({ res, on_ok: on_subscription_save_ok })
        )
        
    }

    const onClosePayWithCreditsResultsModal = () => {
        setPayWithCreditsResult(null)
    }

    const onCreateSubscription = (values, formik_funcs) => {
        if (selectedTopupId) {
            values['initial_topup'] = selectedTopupId
        }
        const on_ok = (json) => {
            dispatch(subscriptionList.invalidateList())
            dispatch(subscriptionList.fetchListIfNeeded())
            dispatch(customerList.invalidateCustomer())
            dispatch(customerList.ensureCustomerLoaded())
            handlePaySubscriptionWithMollie(json)
        }

        if ( values.payment_method === 'credits' ) {
            onShowMakePaymentWithCreditsConfirmationModal(values)
        } else {
            setProcessingSubscription(true)
            return dispatch(subscriptionList.saveNewObject(values)).then((res) =>
                handleSubmitResult({ res, formik_funcs, on_ok })
            )
        }
    }

    const renderMakePaymentWithCreditsConfirmationModal = () => {
        const values = showMakePaymentWithCreditsConfirmationModalForSubscription

        return (

            <>
              <PaySubscriptionsWithCreditsConfirmation
                  isSaving={is_saving}
                  customer={customer}
                  subscription={paymentCostCalculation}
                  onConfirm={() => handlePaySubscriptionWithCredits(values)}
                  onCancel={onCloseMakePaymentWithCreditsConfirmationModal}
              />

            </>
        )
    }
    
    const renderPayWithCreditsResult = () => {
        return <PaySubscriptionsWithCreditsResult onClose={onClosePayWithCreditsResultsModal} />
    }    

    return (
        <>
          <WrappingBusyMask is_loading={is_loading || is_saving}>
            {!is_loading && (
                <>
                  <Formik
                      initialValues={initial_values}
                      onSubmit={onCreateSubscription}
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                  >
                    {(formik_props) => {
                        return (
                            <Form>
                              <FormikGeneralFormErrors
                                  render={(msg) => (
                                      <Row>
                                        <Col>{msg}</Col>
                                      </Row>
                                  )}
                              />

                              <Card
                                  variant="white_wide_and_top_padding"
                                  with_padding_below={false}
                              >
                                <NewSubscriptionCardForm
                                    formik_props={formik_props}
                                    initial_product_short_id={initial_product_short_id}
                                    onNewPaymentCostCalculationId={
                                    setPaymentCostCalculationId
                                    }
                                    onNewSelectedTopupId={setSelectedTopupId}
                                />
                              </Card>

                            </Form>
                        )
                    }}
                  </Formik>
                  
                </>
            )}
          </WrappingBusyMask>
          { payWithCreditsResult && renderPayWithCreditsResult() }
          { showMakePaymentWithCreditsConfirmationModalForSubscription && renderMakePaymentWithCreditsConfirmationModal() }

        </>
    )
}
